<template>
  <b-container class="main-container d-flex justify-content-center mt-5 mb-5">
    <b-row class="main-row">
      <b-col class="main-col">
        <h2>
          Edit recruiter {{ form.user.full_name }}
        </h2>
        <b-form
          class="mt-5"
          @submit.prevent="onSubmit"
        >
          <b-form-group
            id="input-group-25"
            class="pb-4"
            label="First name*"
            label-for="input-25"
          >
            <b-form-input
              id="input-25"
              v-model="form.user.first_name"
              name="first_name"
              placeholder="Your first name"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-26"
            class="pb-4"
            label="Last name*"
            label-for="input-26"
          >
            <b-form-input
              id="input-26"
              v-model="form.user.last_name"
              name="last_name"
              placeholder="Your last name"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-24"
            class="pb-4"
            label="Profile picture"
            label-for="input-24"
          >
            <p>
              If you don't want to add your pic, you'll get a cute illustration instead 💁‍♀️
            </p>
            <img
              v-if="form.user.profile_picture"
              :src="form.user.profile_picture"
              class="profile_picture"
            >
            <b-form-file
              v-model="profile_picture"
              :state="Boolean(profile_picture)"
              accept=".jpg, .png, .gif, .jpeg"
              name="profile_picture"
              size="sm"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="uploadPic"
            />
          </b-form-group>
          <b-form-group
            id="input-group-4"
            class="pb-4"
            label="LinkedIn*"
            label-for="input-4"
          >
            <b-form-input
              id="input-4"
              v-model="form.user.linkedin"
              name="linkedIn"
              type="url"
              placeholder="Your linkedIn profile (URL)"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-country"
            class="pb-4"
            label="Where are you based?*"
            label-for="input-country"
          >
            <b-form-select
              id="input-country"
              v-model="form.country"
              name="country_id"
              :options="countryOptions"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-5"
            class="pb-4"
            label="Recruiter type*"
            label-for="input-5"
          >
            <b-form-select
              id="input-5"
              v-model="form.type"
              name="type"
              :options="recruiterTypes"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="['admin', 'manager'].includes($store.state.auth.user.role)"
            id="input-group-5"
            class="pb-4"
            label="Status*"
            label-for="input-5"
          >
            <b-form-select
              id="input-5"
              v-model="form.status"
              name="status"
              :options="recruiterStatuses"
              required
            />
          </b-form-group>
          <!-- <b-form-group
            id="input-group-6"
            class="pb-4"
            label="Top skill*"
            label-for="input-6"
          >
            <b-form-select
              id="input-6"
              v-model="form.top_skill_id"
              name="top_skill"
              :options="recruiterTopSkills"
              required
            />
          </b-form-group> -->
          <b-form-group
            id="input-group-7"
            class="pb-4 mb-0"
            label="Services provided*"
            label-for="input-7"
          >
            <FormTags
              v-model="form.services"
              name="services"
              :options="servicesOptions"
              button-text="Select services"
            />
          </b-form-group>
          <b-form-group
            id="input-group-8"
            class="pb-4"
            label="Seniority*"
            label-for="input-8"
          >
            <b-form-select
              id="input-8"
              v-model="form.seniority"
              name="seniority"
              :options="seniorityOptions"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-9"
            class="pb-4 mb-0"
            label="Role preference*"
            label-for="input-9"
          >
            <FormTags
              v-model="form.roles"
              name="roles"
              :options="roleOptions"
              button-text="Select roles"
            />
          </b-form-group>
          <b-form-group
            id="input-group-11"
            class="pb-4 mb-0"
            label="Industry preference*"
            label-for="input-11"
          >
            <FormTags
              v-model="form.industries"
              name="industries"
              :options="industryOptions"
              button-text="Select industries"
            />
          </b-form-group>
          <b-form-group
            id="input-group-12"
            class="pb-4 mb-0"
            label="Region expertise*"
            label-for="input-12"
          >
            <FormTags
              v-model="form.regions"
              name="regions"
              :options="regionsOptions"
              button-text="Select regions"
            />
          </b-form-group>
          <b-form-group
            id="input-group-13"
            class="pb-4"
            label="Country expertise*"
            label-for="input-13"
          >
            <FormTags
              v-model="form.local_insights"
              name="local_insights"
              :options="countryOptions"
              button-text="Select countries"
            />
          </b-form-group>
          <b-form-group
            id="input-group-14"
            class="pb-4"
            label="Previous clients/employers"
            label-for="input-14"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.previous_clients"
              name="previous_clients"
              placeholder="Name only some of the trophy clients"
              rows="3"
              no-resize
            />
          </b-form-group>
          <b-form-group
            id="input-group-15"
            class="pb-4 mb-0"
            label="Your preferred compensation model*"
            label-for="input-15"
          >
            <FormTags
              v-model="form.pricing_models"
              name="pricing_models"
              :options="feeOptions"
              button-text="Select pricing models"
            />
          </b-form-group>
          <b-form-group
            id="input-group-24"
            class="pb-4 mb-0"
            label="Pricing*"
            label-for="input-24"
          >
            <FormTags
              v-model="form.fee_ranges"
              name="fee_ranges"
              :options="feeRangeOptions"
              button-text="Select your fee ranges"
            />
          </b-form-group>
          <b-form-group
            id="input-group-16"
            class="pb-4"
            label="Availability*"
            label-for="input-16"
          >
            <b-form-select
              id="input-16"
              v-model="form.availability"
              name="availability"
              :options="availabilityOptions"
              required
            />
            <b-form-datepicker
              v-if="form.availability === 'available_from' || (form.availability !== 'Available' && form.availability !== 'Not available')"
              v-model="available_from"
              class="datepicker"
              name="datepicker"
              size="sm"
              placeholder="Choose Date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: undefined }"
            />
          </b-form-group>
          <b-form-group
            id="input-group-18"
            class="pb-4"
            label="Description*"
            label-for="input-18"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              name="description"
              placeholder="What makes you special? Describe your superskills and distinctive experiences with 3-5 sentences. Good luck! 💪"
              rows="3"
              no-resize
              required
            />
          </b-form-group>

          <b-row class="mt-5 mb-5">
            <b-col>
              <b-row class="mb-4">
                <b-col class="text-center h7 p-0">
                  What are your goals and expectations?
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <p class="second-headline h9">
                    Tell us about your expectations so we know how we can help you the most.
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            cols="1"
            cols-sm="3"
            align-h="center"
            class="text-center ml-5 mr-5 h9 mb-6"
          >
            <b-col
              v-for="goal in goalOptions"
              :key="goal.id"
              class="answer-box text-center ml-2 mr-2 mb-4 pt-1"
              :class="{ active: form.goals.indexOf(goal.id) > -1 }"
              @click="toggleGoal(goal.id)"
            >
              <p class="m-4">
                {{ goal.label }}
              </p>
            </b-col>
            <b-col
              class="answer-box ml-2 mr-2 mb-4 pt-1"
              :class="{ active: otherActive }"
              @click="otherActive = !otherActive"
            >
              <p class="m-4">
                Other (enter your answer)
              </p>
            </b-col>
            <b-textarea
              v-if="otherActive"
              id="other"
              v-model="form.other_goal"
              autofocus
              name="other"
              rows="4"
              class="col answer-box ml-2 mr-2 mb-4 pt-1"
              placeholder="Enter text here"
            />
          </b-row>

          <label class="h8">
            Your native language(s) *
          </label>
          <FormTags
            v-model="form.native_languages"
            name="native_languages"
            :options="languageOptions"
            button-text="Native language(s)"
            class="borderless-tags"
            required
          />
          <br>
          <b-form-group
            id="input-group-21"
            class="pb-4"
            label="Fluency in English*"
            label-for="input-21"
          >
            <b-form-select
              id="input-21"
              v-model="form.english_level"
              name="english_level"
              :options="englishFluencyOptions"
              required
            />
          </b-form-group>
          <label class="h8">
            Additional working languages
          </label>
          <FormTags
            v-model="form.languages"
            name="additional_languages"
            :options="languageOptions"
            button-text="Additional working language(s)"
            class="borderless-tags"
          />
          <b-button
            class="mb-4"
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <b-modal
            id="admin-submit-modal"
            class="m-auto"
            size="lg"
            title="Hurray, recruiter edited"
            centered
          >
            <p class="my-4">
              What would you like to do next?
            </p>
            <template #modal-footer>
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button
                size="sm"
                variant="primary-light"
                to="/admin/recruiter"
              >
                Go Back To All Recruiters
              </b-button>
            </template>
          </b-modal>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import FormTags from '@/components/FormTags'
import { RECRUITER_STATUSES } from '@/helpers/statuses'

const SERVICE_OPTIONS_ORDER = {
  'Recruitment planning': 1,
  'Market research': 2,
  'Acquisition strategy': 3,
  Sourcing: 4,
  'Screening calls': 5,
  Interviewing: 6,
  'Pipeline management': 7,
  'Recruitment marketing': 8,
  'Employer branding': 9,
  'Salary negotiations': 10,
  'Recruitment software implementation': 11,
  'Recruitment process development': 12,
}

const SENIORITY_OPTIONS_ORDER = {
  Entry: 1,
  Junior: 2,
  'Mid-level': 3,
  Senior: 4,
  Expert: 5,
}

const GEO_REGIONS_ORDER = {
  Globally: 1,
  'North America': 2,
  'Central America': 3,
  'South America': 4,
  'The Caribbean': 5,
  Africa: 6,
  'Western Europe': 7,
  'Northern Europe': 8,
  'Eastern Europe': 9,
  'Southern Europe': 10,
  'Middle East': 11,
  'Central Asia': 12,
  'East Asia': 13,
  'South Asia': 14,
  'Southeast Asia': 15,
  Oceania: 16,
}

const PRICING_MODEL_OPTIONS = {
  'Hourly fee': 1,
  'Daily rate': 2,
  'Monthly subscription': 3,
  'Fixed fee with pre-payment': 4,
  'Contingency (% of the annual salary)': 5,
  'Open to different models': 8,
  'Fixed fee with a retainer payment': 6,
  'Fixed fee per qualified lead + bonus per hire': 7,
  Other: 9,
}

export default {
  name: 'WebForm',
  components: {
    FormTags,
  },
  data () {
    return {
      form: {
        type: null,
        top_skill_id: null,
        services: [],
        seniority: null,
        roles: [],
        industries: [],
        regions: [],
        local_insights: [],
        previous_clients: '',
        pricing_models: [],
        availability: null,
        description: '',
        goals: '',
        native_languages: [],
        english_level: null,
        languages: [],
        user: {
          first_name: '',
          last_name: '',
          linkedin: '',
          profile_picture: '',
        },
      },
      available_from: null,
      profile_picture: null,
      recruiterTypes: [
        { text: 'Select One', value: null },
        'Agency',
        'Freelancer',
        'Interim',
        'In-house',
      ],
      recruiterTopSkills: [],
      servicesOptions: [],
      seniorityOptions: [],
      roleOptions: [],
      industryOptions: [],
      regionsOptions: [],
      feeOptions: [],
      feeRangeOptions: [],
      availabilityOptions: [
        'Available',
        'Not available',
        'available_from',
      ],
      englishFluencyOptions: [
        { text: 'Select One', value: null },
        'Basic',
        'Conversational',
        'Fluent',
        'Native or bilingual',
      ],
      recruiterStatuses: RECRUITER_STATUSES,
      countryOptions: [],
      languageOptions: [],
      goalOptions: [],
      otherActive: null,
    }
  },
  mounted () {
    axios.get('/public/v2/values/languages')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.languageOptions = temp
      })
    axios.get('/public/v2/values/goals')
      .then(({ data }) => {
        this.goalOptions = data
      })
    // Fetch all the dropdown values
    axios.get('/public/v2/values/skills')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(skill => skill.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(skill => skill.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temp variable
        this.recruiterTopSkills = temp
      })
    axios.get('/public/v2/values/services')
      .then(({ data }) => {
        // Creating temporary variable where to hold data/options
        let temp = this.mapValues(data)
        // mapping through temp and adding order key + value
        temp = temp.map(option => {
          option.order = SERVICE_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.servicesOptions = temp
      })
    axios.get('/public/v2/values/seniorities')
      .then(({ data }) => {
        let temp = this.mapValues(data.filter(s => s.label !== 'Not important'), true)
        temp = temp.map(option => {
          option.order = SENIORITY_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.seniorityOptions = temp
      })
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(role => role.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(role => role.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.roleOptions = temp
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(industry => industry.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(industry => industry.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.industryOptions = temp
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = GEO_REGIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.regionsOptions = temp
      })
    axios.get('/public/v2/values/pricing_models')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = PRICING_MODEL_OPTIONS[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.feeOptions = temp
      })
    axios.get('/public/v2/values/fee_ranges')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.feeRangeOptions = temp
      })
    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.countryOptions = temp
      })

    // Get recruiter himself
    axios.get(`/v2/recruiters/${this.$route.params.id}`)
      .then(({ data }) => {
        this.form = data
        this.$set(this.form, 'country', (data.country || {}).id)
        this.$set(this.form, 'services', data.services.map(o => o.id))
        this.$set(this.form, 'seniority', (data.seniority || {}).id)
        this.$set(this.form, 'roles', data.roles.map(o => o.id))
        this.$set(this.form, 'industries', data.industries.map(o => o.id))
        this.$set(this.form, 'regions', data.regions.map(o => o.id))
        this.$set(this.form, 'pricing_models', data.pricing_models.map(o => o.id))
        this.$set(this.form, 'fee_ranges', data.fee_ranges.map(o => o.id))
        this.$set(this.form, 'local_insights', data.local_insights.map(o => o.id))
        this.$set(this.form, 'native_languages', data.native_languages.map(o => o.id))
        this.$set(this.form, 'languages', data.languages.map(o => o.id))
        this.$set(this.form, 'goals', data.goals.map(o => o.id))
        if (data.other_goal) {
          this.otherActive = true
        }
        // Check if availability is date, then make available_from as date too
        if (data.availability && data.availability !== 'Available' && data.availability !== 'Not available') {
          this.available_from = data.availability
          // this.available_from has been updated to date, now let's change recruiter one to "available_from"
          // So we can show that radio button checked
          data.availability = 'available_from'
        }
        // this.$set(this.form, 'local_insights', data.local_insights)
      })
  },
  methods: {
    mapValues (data) {
      const dataArr = data.map(option => {
        option.value = option.id
        option.text = option.label
        return option
      })
      return dataArr
    },
    onSubmit (event) {
      event.preventDefault()
      if (['admin', 'manager'].includes(this.$store.state.auth.user.role)) {
        // Since some certain date is selected. Make available from from that date
        if (this.form.availability === 'available_from') {
          this.form.availability = this.available_from
        }
        axios.put(`/admin/v2/recruiters/${this.form.id}`, this.form)
          .then(() =>
            this.$bvModal.show('admin-submit-modal'))
      } else {
        axios.put(`/v2/recruiters/${this.form.id}`, this.form)
          .then(() => {
            this.$router.push(`/recruiter/${this.$route.params.id}`)
          })
      }
    },
    uploadPic (event) {
      const form = new FormData()
      form.set('file', event.target.files[0])
      axios.post('/v2/upload', form)
        .then(({ data }) => {
          this.form.user.profile_picture = data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .main-row {
    width: 550px;
  }
  .profile_picture {
    max-width: 100px;
    max-height: 100px;
  }
  .h8 {
    font-weight: 600;
    font-size: 1rem;
    color: #455360;
  }

  .answer-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px #EDE1DF;
    border: 2px solid transparent;
    border-radius: 12px;
    color: #323031;
    font-size: 16px;
    min-height: 80px;
    &:hover {
      box-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
    }
    &.active {
      border: 2px solid #EE8B7B;
    }
  }
}
</style>
